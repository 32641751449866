import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../shadcn/table";
import addApplicantAction from "../../../../redux/Actions/addApplicantAction";
import isLoadingAction from "../../../../redux/Actions/isLoadingAction";
import ApplicantDetial from "../../../ApplicantDetial";
import ZIREditForm from "../../../form/editForm/ZIREditForm";
import ZSAEditForm from "../../../form/editForm/ZSAEditForm";
import ZTTEditForm from "../../../form/editForm/ZTTEditForm";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "../../../shadcn/checkbox";
import TextBox from "../../../TextBox";
import { CiMenuKebab } from "react-icons/ci";
import { Statusbar } from "./Statusbar";
import TableLoader from "./TableLoader";
import { useState } from "react";
import axios from "axios";

function ColumnView({ tableHeader, tableScheme, tableData }) {
  const isLoading = useSelector((state) => state.isLoadingReducer);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [dropdownOpenRow, setDropdownOpenRow] = useState(null); // Track the row with an open dropdown
  const userId = useSelector((state) => state.filterReducer);
  const dispatch = useDispatch();

  const DeleteAction = async (emp_id) => {
    try {
      dispatch(isLoadingAction(true));
      const DELETE_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants/${emp_id}`;
      await axios.delete(DELETE_EMP);
      const GET_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants`;
      const response = await axios.get(GET_EMP);
      dispatch(addApplicantAction((response?.data).reverse()));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(isLoadingAction(false));
    }
  };

  const EditAction = (value) => {
    setIsEdit(true);
    setValues(value);
  };

  const handleMouseClick = (rowId) => {
    setDropdownOpenRow((prev) => (prev === rowId ? null : rowId)); // Toggle dropdown for this row
  };

  const [value, setValue] = useState([
    {
      cnic: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
  ]);

  const closeModel = () => {
    setIsModelOpen(false);
  };

  const closeEditeModel = () => {
    setIsEdit(false);
  };

  const showInfo = (e) => {
    setValue(tableData.filter((value) => value._id === e.currentTarget.id));
    setIsModelOpen(true);
  };

  const [data] = value;
  const GoogleDrivelinks = data.GoogleDrivelinks;
  let listData;
  if (userId === "66fc1c3696ba6984b614bd4a") {
    listData = [
      {
        name: "First Name",
        value: data.firstName,
        id: "firstName",
        dashed: false,
      },
      {
        name: "Last Name",
        value: data.lastName,
        id: "lastName",
        dashed: false,
      },
      { name: "Email", value: data.email, id: "email", dashed: false },
      {
        name: "Phone Number",
        value: data.phoneNumber,
        id: "phoneNumber",
        dashed: false,
      },
      { name: "Address", value: data.address, id: "address", dashed: false },
      { name: "CNIC", value: data.cnic, id: "cnic", dashed: true },
      {
        name: "Desired Country",
        value: data.desiredCountry,
        id: "desiredCountry",
        dashed: false,
      },
      {
        name: "Date of Birth",
        value: data.dateOfBirth,
        id: "dateOfBirth",
        dashed: false,
      },
      { name: "Passport", value: data.passport, id: "passport", dashed: false },
      {
        name: "Occupation",
        value: data.occupation,
        id: "occupation",
        dashed: false,
      },
      { name: "Region", value: data.region, id: "region", dashed: true },
      {
        name: "Years of Experience",
        value: data.yearsOfExperience,
        id: "yearsOfExperience",
        dashed: false,
      },
      {
        name: "Highest Level Of Education / Year",
        value: data.levelOfEducation,
        id: "levelOfEdcuation",
        dashed: true,
      },
    ];
  } else if (userId === "66fc1c8d96ba6984b614bd4c") {
    listData = [
      {
        name: "First Name",
        value: data.firstName,
        id: "firstName",
        dashed: false,
      },
      {
        name: "Last Name",
        value: data.lastName,
        id: "lastName",
        dashed: false,
      },
      { name: "Email", value: data.email, id: "email", dashed: false },
      {
        name: "Phone Number",
        value: data.phoneNumber,
        id: "phoneNumber",
        dashed: false,
      },
      { name: "Address", value: data.address, id: "address", dashed: false },
      { name: "CNIC", value: data.cnic, id: "cnic", dashed: true },
      {
        name: "Desired Country",
        value: data.desiredCountry,
        id: "desiredCountry",
        dashed: false,
      },
      {
        name: "IELTS/PTE Score",
        value: data.IELTS_PTE_Score,
        id: "IELTS_PTE_Score",
        dashed: false,
      },
      {
        name: "Date of Birth",
        value: data.dateOfBirth,
        id: "dateOfBirth",
        dashed: false,
      },
      { name: "Passport", value: data.passport, id: "passport", dashed: false },
      {
        name: "Occupation",
        value: data.occupation,
        id: "occupation",
        dashed: false,
      },
      { name: "Region", value: data.region, id: "region", dashed: true },
      { name: "matric", value: data.matric, id: "matric", dashed: false },
      {
        name: "Highest Level Of Education / Year",
        value: data.levelOfEducation,
        id: "levelOfEdcuation",
        dashed: true,
      },
    ];
  } else if (userId === "66fc1cad96ba6984b614bd4e") {
    listData = [
      {
        name: "First Name",
        value: data.firstName,
        id: "firstName",
        dashed: false,
      },
      {
        name: "Last Name",
        value: data.lastName,
        id: "lastName",
        dashed: false,
      },
      { name: "Email", value: data.email, id: "email", dashed: false },
      {
        name: "Phone Number",
        value: data.phoneNumber,
        id: "phoneNumber",
        dashed: false,
      },
      { name: "Address", value: data.address, id: "address", dashed: false },
      { name: "CNIC", value: data.cnic, id: "cnic", dashed: true },
      {
        name: "Desired Country",
        value: data.desiredCountry,
        id: "desiredCountry",
        dashed: false,
      },
      {
        name: "IELTS/PTE Score",
        value: data.IELTS_PTE_Score,
        id: "IELTS_PTE_Score",
        dashed: false,
      },
      {
        name: "Date of Birth",
        value: data.dateOfBirth,
        id: "dateOfBirth",
        dashed: false,
      },
      { name: "Passport", value: data.passport, id: "passport", dashed: false },
      {
        name: "Occupation",
        value: data.occupation,
        id: "occupation",
        dashed: false,
      },
      { name: "Region", value: data.region, id: "region", dashed: true },
      { name: "matric", value: data.matric, id: "matric", dashed: false },
      {
        name: "Highest Level Of Education / Year",
        value: data.levelOfEducation,
        id: "levelOfEdcuation",
        dashed: true,
      },
    ];
  }

  return (
    <>
      {userId === "66fc1c3696ba6984b614bd4a" ? (
        <ZIREditForm isOpen={isEdit} onClose={closeEditeModel} value={values} />
      ) : userId === "66fc1c8d96ba6984b614bd4c" ? (
        <ZSAEditForm isOpen={isEdit} onClose={closeEditeModel} value={values} />
      ) : (
        userId === "66fc1cad96ba6984b614bd4e" && (
          <ZTTEditForm
            isOpen={isEdit}
            onClose={closeEditeModel}
            value={values}
          />
        )
      )}
      <ApplicantDetial
        isOpen={isModelOpen}
        onClose={closeModel}
        listData={listData}
        GoogleDrivelinks={GoogleDrivelinks}
      />

      <div className="w-full overflow-x-scroll lg:overflow-visible">
        <Table className="min-w-full table-auto">
          <TableHeader>
            <TableRow>
              <TableHead className="text-right bg-[#f9fbfd] border-none font-bold tracking-wider text-[11px] text-gray-500">
                <Checkbox id="terms" />
              </TableHead>
              {tableHeader.map((value, index) => (
                <TableHead
                  key={index}
                  className="bg-[#f9fbfd] border-none font-bold tracking-wider text-[11px] text-gray-500"
                >
                  {value}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableBody>
              {tableData.length === 0 && (
                <TableRow className="bordercursor-pointer bg-white rounded-md">
                  <TableCell
                    className="font-medium text-gray-500 bg-inherit"
                    colSpan="8"
                  >
                    <div className="w-full flex justify-center bg-inherit border border-grey-200 p-6 rounded-lg">
                      <TextBox parm1="No Record Here" parm2="" parm3="" />
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {tableData.map((value, index) => (
                <TableRow
                  key={index}
                  id={value._id}
                  className="border-none cursor-pointer bg-white hover:bg-blue-100"
                >
                  <TableCell className="font-medium bg-inherit">
                    <Checkbox id="terms" />
                  </TableCell>
                  {tableScheme.map((property, index) => (
                    <TableCell
                      key={index}
                      id={value._id}
                      className="font-medium text-gray-500 bg-inherit truncate"
                    >
                      {property[0] !== "status" && (
                        <span
                          onClick={showInfo}
                          id={value._id}
                          className="bg-inherit"
                        >
                          <TextBox
                            parm1={value[property[0]]}
                            parm2={value[property[1]] || ""}
                            parm3=""
                          />
                        </span>
                      )}
                      {property[0] !== "status" && (
                        <span
                          onClick={showInfo}
                          id={value._id}
                          className="bg-inherit"
                        >
                          <TextBox
                            parm1=""
                            parm2=""
                            parm3={value[property[2]]}
                          />
                        </span>
                      )}
                      {property[0] === "status" && (
                        <Statusbar emp_id={value._id} value={value} />
                      )}
                    </TableCell>
                  ))}
                  <TableCell
                    id={value._id}
                    className="text-right bg-inherit cursor-pointer relative"
                    onClick={() => handleMouseClick(value._id)} // Pass row ID
                  >
                    <CiMenuKebab className="bg-inherit" />
                    {dropdownOpenRow === value._id && ( // Check if this row's dropdown is open
                      <ul className="absolute top-[60%] right-[20px] z-[500] px-[10px] py-[5px] min-w-40 rounded-lg bg-white shadow-lg shadow-gray-200 overflow-hidden min-h-0 font-[Poppins]">
                        <li
                          className="flex items-center bg-white p-[10px] rounded-[5px] hover:text-gray-300"
                          onClick={() => EditAction(value)}
                        >
                          <button className="border-none outline-none mt-1 pl-[7px] bg-transparent text-green-400 text-[15px] ">
                            Edit
                          </button>
                        </li>
                        <li
                          className="flex items-center bg-white p-[10px] rounded-[5px] hover:text-gray-300"
                          onClick={() => DeleteAction(value._id)}
                        >
                          <button className="border-none outline-none mt-1 pl-[7px] bg-transparent text-red-400 text-[15px] ">
                            Delete
                          </button>
                        </li>
                      </ul>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
    </>
  );
}

export default ColumnView;
