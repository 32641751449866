import Home from "./routes/Home";
import Login from "./Authentication/Login";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./Authentication/RequireAuth";
import PersistLogin from "./Authentication/PersistLogin";
import Unauthorized from "./Authentication/Unauthorized";

function App() {
  return (
    <>
      <Routes>
        {/* public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* protected routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Home />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
