import addCompanyAction from "../../../redux/Actions/addCompanyAction";
import { RxCrossCircled } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { useState } from "react";

const AddCompanyForm = ({ isOpen, onClose }) => {

  const dispatch = useDispatch();
  const [value, setValue] = useState({ name: "", url: "", email: "", score: "" });

  return (
    <>
      <div className={`fixed top-0 z-[500] left-0 w-full h-full sidebar_animation flex justify-end bg-[#717D8C] bg-opacity-25 ${isOpen ? "" : "hidden"}`}>
        <div className="overflow-scroll fixed top-0 right-0 sidebar_animation bg-[#F9FBFD] p-5 lg:w-[500px] md:w-[500px] sm:w-[500px] h-full rounded">
          <div className="flex justify-between border-b pb-3">
            <h3 className="font-semibold text-lg">Add Client</h3>
            <button onClick={onClose} className="font-semibold text-gray-400 text-[32px]" >
              <RxCrossCircled />
            </button>
          </div>
          <form className="mt-5 text-sm" onSubmit={(e) => {
            e.preventDefault();
            dispatch(addCompanyAction(value));
            setValue({ name: "", url: "", email: "", score: "" });
            onClose();
          }}>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="name">
                Company Name:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input type="text" name="name" id="name" className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none" placeholder="Enter your company" value={value.name} onChange={(e) => setValue({ ...value, name: e.currentTarget.value }) } required/>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="url">
                Website URL
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input type="text" name="url" id="url" className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none" placeholder="Enter URL here..." value={value.url} onChange={(e) => setValue({ ...value, url: e.currentTarget.value }) } required />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="email">
                Email:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input type="email" name="email" id="email" className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none" placeholder="Eamil@gmail.com" value={value.email} onChange={(e) => setValue({ ...value, email: e.currentTarget.value })} required />
            </div>

            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="score">Score:</label>
              <input type="text" name="score" id="score" className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none" placeholder="Enter Score" value={value.score} onChange={(e) => setValue({ ...value, score: e.currentTarget.value })} />
            </div>
            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-9 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" >
              Save
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCompanyForm;
