import { Tabs, TabsList, TabsTrigger } from "../../../shadcn/tabs";
import { CiGrid41 } from "react-icons/ci";
import { FaListUl } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import gridColumnAction from "../../../../redux/Actions/gridColumnAction";

function GridColumnView() {
  const dispatch = useDispatch();
  return (
    <div className="border border-blue-100 rounded-md h-full">
      <Tabs defaultValue="account" className="w-auto h-full">
        <TabsList className="grid w-full grid-cols-2 p-0 h-full">
          <TabsTrigger
            value="account"
            className="data-[state=active]:bg-blue-600 data-[state=active]:text-white  text-gray-600 px-4 h-full"
            onClick={() => dispatch(gridColumnAction(true))}
          >
            <div className="relative group bg-transparent">
              <FaListUl className="bg-transparent text-[18px]" />
              <span className="absolute bottom-full mb-2 hidden w-max bg-gray-800 text-white text-xs rounded-lg py-1 px-2 group-hover:block">
                Column View
              </span>
            </div>
          </TabsTrigger>
          <TabsTrigger
            value="password"
            className="data-[state=active]:bg-blue-600 data-[state=active]:text-white text-gray-600 px-4 h-full"
            onClick={() => dispatch(gridColumnAction(false))}
          >
            <div className="relative group bg-transparent">
              <CiGrid41 className="bg-transparent text-[18px]" />
              <span className="absolute bottom-full mb-2 hidden w-max bg-gray-800 text-white text-xs rounded-lg py-1 px-2 group-hover:block">
                Grid View
              </span>
            </div>
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  );
}

export default GridColumnView;
