const initialState = [];
const addCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADDCOMANY":
      return [...state, action.value];
    default:
      return state;
  }
};

export default addCompanyReducer;
