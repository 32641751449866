import { TableBody, TableCell, TableRow } from "../../../shadcn/table";
import Loader from "./Loader";

const TableLoader = () => {
  return (
    <TableBody>
      <TableRow className="border-none cursor-pointer">
        <TableCell className="font-medium bg-white">
          <div class="relative flex w-4 animate-pulse gap-2 bg-white">
            <div class="flex-1">
              <div class="h-3 rounded-lg bg-slate-300 text-sm"></div>
            </div>
          </div>
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
      </TableRow>
      <TableRow className="border-none cursor-pointer">
        <TableCell className="font-medium bg-white">
          <div class="relative flex w-4 animate-pulse gap-2 bg-white">
            <div class="flex-1">
              <div class="h-3 rounded-lg bg-slate-300 text-sm"></div>
            </div>
          </div>
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
      </TableRow>
      <TableRow className="border-none cursor-pointer">
        <TableCell className="font-medium bg-white">
          <div class="relative flex w-4 animate-pulse gap-2 bg-white">
            <div class="flex-1">
              <div class="h-3 rounded-lg bg-slate-300 text-sm"></div>
            </div>
          </div>
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
      </TableRow>
      <TableRow className="border-none cursor-pointer">
        <TableCell className="font-medium bg-white">
          <div class="relative flex w-4 animate-pulse gap-2 bg-white">
            <div class="flex-1">
              <div class="h-3 rounded-lg bg-slate-300 text-sm"></div>
            </div>
          </div>
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
        <TableCell className="font-medium bg-white">
          <Loader />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default TableLoader;
