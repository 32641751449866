import { Avatar, AvatarImage } from "./shadcn/avatar";
import { PiSignOutLight } from "react-icons/pi";
import { HiUserCircle } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import TextBox from "./TextBox";
import { useState, useEffect } from "react";

const ProfileComponent = () => {
  const username = JSON.parse(localStorage.getItem("username"));
  const email = JSON.parse(localStorage.getItem("email"));
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const navigate = useNavigate();
  const handleMouseClick = () => {
    setIsDropDownOpen((prev) => !prev);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  }
  
  return (
    <div className="bg-white relative">
      <span onClick={handleMouseClick}>
        <Avatar className="cursor-pointer">
          <HiUserCircle className="w-full h-full text-gray-400" />
        </Avatar>
      </span>
      {isDropDownOpen && (
        <ul className="absolute top-[100%] right-[20px] z-50 px-[10px] py-[5px] min-w-60 rounded-lg bg-white shadow-lg shadow-gray-200 overflow-hidden min-h-0 dropDownMenu font-[Poppins]">
          <li className="flex items-center bg-white p-[10px] rounded-[5px] border-b border-dashed">
            <button className="border-none outline-none mt-1 pl-[7px] bg-transparent text-gray-400">
              <TextBox parm1={username} parm2="" parm3={email} />
            </button>
          </li>
          <li className="flex items-center bg-white p-[10px] rounded-[5px] border-t border-dashed">
            <span className="text-[22px] bg-white text-gray-400">
              <PiSignOutLight className="bg-white text-red-400" />
            </span>
            <button className="border-none outline-none mt-1 pl-[7px] bg-transparent text-red-400 text-[15px] " onClick={logout}>
              Sign Out
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ProfileComponent;
