import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();
  const from = "/login";

  const goBack = () => navigate(from, { replace: true });
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center ">
        <div className="model-container">
          <div className="bg-indigo-700 text-center p-5 lg:w-[500px] h-[50vh] rounded-xl shadow-md">
            <h2 className="text-xl font-semibold  mt-6 mb-5 uppercase bg-indigo-700 text-white">
              Unauthorized
            </h2>
            <br />
            <div className="mb-3  bg-transparent">
              <p className="text-lg font-semibold bg-indigo-700 text-white">You do not have access to the requested page.</p>   
            </div>
            <div className="mb-5 bg-indigo-700">
              
            </div>
            <div className=" bg-indigo-700">
              <button onClick={goBack} className="hover:shadow-md rounded-md bg-[#aaa6f3] hover:bg-orange-600 py-3 px-8 text-base font-semibold text-white outline-none">
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Unauthorized;
