import React from "react";

const Loader = () => {
  return (
    <div class="relative flex w-full animate-pulse gap-2 bg-white">
      <div class="flex-1">
        <div class="h-[13px] w-full rounded-lg bg-slate-300 text-sm"></div>
      </div>
    </div>
  );
};

export default Loader;
