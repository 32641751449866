function TextBox({ parm1, parm2, parm3 }) {
  return (
    <div className="items-top flex space-x-2 bg-inherit">
      <div className="grid gap-1.5 leading-none bg-inherit">
        <label htmlFor="terms1" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 bg-inherit text-left" >
          <span className="text-[16px] bg-inherit cursor-pointer">
            {parm1 + " " + parm2}
          </span>
        </label>
        <p className="text-sm text-muted-foreground bg-inherit">{parm3}</p>
      </div>
    </div>
  );
}

export default TextBox;
