import isLoadingAction from "../../../redux/Actions/isLoadingAction";
import AddCompanyForm from "../../form/applyForm/AddCompanyForm";
import TableDemo from "./clientsTable/TableDemo";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FaPlus } from "react-icons/fa6";
import axios from "axios";

const Clients = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();

  const openModel = () => {
    setIsModelOpen(true);
  };
  const closeModel = () => {
    setIsModelOpen(false);
  };

  const tableHeader = ["NAME", "URL", "EMAIL", "SCORE", " "];
  const tableScheme = [["name"], ["url"], ["email"], ["score"]];
  useEffect(() => {
    const GET_USER = "https://zamdainternational-crm-api.vercel.app/api/users";
    const request = async () => {
      try {
        dispatch(isLoadingAction(true));
        const response = await axios.get(GET_USER);
        setTableData(response?.data);
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(isLoadingAction(false));
      }
    };
    request();
  }, []);

  return (
    <>
      <TableDemo
        tableHeader={tableHeader}
        tableScheme={tableScheme}
        tableData={tableData}
        status={false}
      />
      <button
        className="fixed bottom-2 right-2 md:bottom-7 md:right-2"
        onClick={openModel}
      >
        <div className="border p-4 bg-[#2C7BE5] rounded-[100%]">
          <FaPlus className="bg-[#2C7BE5] text-white text-xl font-bold" />
        </div>
      </button>
      <AddCompanyForm isOpen={isModelOpen} onClose={closeModel} />
    </>
  );
};

export default Clients;
