import addApplicantAction from "../../../redux/Actions/addApplicantAction";
import isLoadingAction from "../../../redux/Actions/isLoadingAction";
import { useDispatch, useSelector } from "react-redux";
import { RxCrossCircled } from "react-icons/rx";
import { useRef, useState } from "react";
import { toast } from "react-toastify";

import axios from "axios";

const ZSAApplyForm = ({ isOpen, onClose }) => {
  const userId = useSelector((state) => state.filterReducer);
  const isLoading = useSelector((state) => state.isLoadingReducer);

  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);

  let GoogleDrivelinks = [];
  const handleClick = async () => {
    let formData = new FormData();
    files.forEach((file) => {
      formData.append("files[]", file.data);
    });
    // Get the folder ID (make sure this is defined in your component state or passed as a prop)
    const folderId = "15KHohaF2aJoRC7P4nzubuuuojOA7MOiE"; // Replace with the actual folder ID you want to use

    // Append the folder ID to the form data
    formData.append("folderId", folderId);
    try {
      const response = await axios.post(
        "https://zamdainternational-crm-api.vercel.app/api/upload/upload-file-to-google-drive",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }, // Set content type for multipart form data
        }
      );
      GoogleDrivelinks = response?.data?.fileIds;
    } catch (err) {
      const notify = () => toast.error("Upload Failed");
      notify();
      throw Error("Upload Failed");
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setFiles([]);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filesWithPreview = selectedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
      data: file,
    }));
    setFiles(filesWithPreview);
  };

  const dispatch = useDispatch();
  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    cnic: "",
    desiredCountry: "",
    dateOfBirth: "",
    passport: "",
    occupation: "",
    region: "",
    levelOfEducation: "",
    GoogleDrivelinks: [],
    status: "Active",
    type: "ZamdaConsultant",
    IELTS_PTE_Score: "",
    matric: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const CREATE_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants`;
    try {
      dispatch(isLoadingAction(true));
      await handleClick();
      await axios.post(
        CREATE_EMP,
        JSON.stringify({
          firstName: value.firstName,
          lastName: value.lastName,
          phoneNumber: value.phoneNumber,
          email: value.email,
          address: value.address,
          cnic: value.cnic,
          desiredCountry: value.desiredCountry,
          dateOfBirth: value.dateOfBirth,
          passport: value.passport,
          occupation: value.occupation,
          region: value.region,
          levelOfEducation: value.levelOfEducation,
          GoogleDrivelinks: GoogleDrivelinks,
          status: value.status,
          type: value.type,
          IELTS_PTE_Score: value.IELTS_PTE_Score,
          matric: value.matric,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const GET_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants`;
      const response = await axios.get(GET_EMP);
      dispatch(addApplicantAction((response?.data).reverse()));
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      } else if (err.response?.status === 409) {
        console.log("Username Taken");
      } else {
        console.log("Registration Failed");
      }
    } finally {
      dispatch(isLoadingAction(false));
    }
    setValue({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      address: "",
      cnic: "",
      desiredCountry: "",
      dateOfBirth: "",
      passport: "",
      occupation: "",
      region: "",
      levelOfEducation: "",
      GoogleDrivelinks: [],
      status: "Active",
      type: "ZamdaConsultant",
      IELTS_PTE_Score: "",
      matric: "",
    });
    onClose();
    setFiles([]);
  };
  return (
    <>
      <div
        className={`fixed top-0 z-[500] left-0  w-full h-full flex justify-end bg-[#717D8C] bg-opacity-25 ${isOpen ? "" : "hidden"
          }`}
      >
        <div className="overflow-scroll fixed top-0 right-0 sidebar_animation  bg-[#F9FBFD] p-5 lg:w-[500px] md:w-[500px] sm:w-[500px] h-full rounded">
          <div className="flex justify-between border-b pb-3">
            <h3 className="font-semibold text-lg">Add Applicant</h3>
            <button
              onClick={onClose}
              className="font-semibold text-gray-400 text-[32px]"
            >
              <RxCrossCircled />
            </button>
          </div>
          <form className="mt-5 text-sm" onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="firstName">
                  First Name:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  placeholder="Enter first name"
                  value={value.firstName}
                  onChange={(e) =>
                    setValue({ ...value, firstName: e.currentTarget.value })
                  }
                  required
                />
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="lastName">
                  Last Name:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  placeholder="Enter last name"
                  value={value.lastName}
                  onChange={(e) =>
                    setValue({ ...value, lastName: e.currentTarget.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="email">Email Address:</label>
              <input
                type="text"
                name="email"
                id="email"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                placeholder="Emai@gmail.com"
                value={value.email}
                onChange={(e) =>
                  setValue({ ...value, email: e.currentTarget.value })
                }
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="phoneNumber">
                Phone Number:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                placeholder="Enter phone number"
                value={value.phoneNumber}
                onChange={(e) =>
                  setValue({ ...value, phoneNumber: e.currentTarget.value })
                }
                required
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="address">
                Address:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>

              <input
                type="text"
                name="address"
                id="address"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                placeholder="Enter your address"
                required
                value={value.address}
                onChange={(e) =>
                  setValue({
                    ...value,
                    address: e.currentTarget.value,
                  })
                }
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="cnic">C.N.I.C Number:</label>
              <input
                type="text"
                name="cnic"
                id="cnic"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                placeholder="C.N.I.C Number"
                value={value.cnic}
                onChange={(e) =>
                  setValue({ ...value, cnic: e.currentTarget.value })
                }
              />
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="desiredCountry">
                  Desired Country:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="desiredCountry"
                  id="desiredCountry"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={value.desiredCountry}
                  onChange={(e) =>
                    setValue({
                      ...value,
                      desiredCountry: e.currentTarget.value,
                    })
                  }
                  placeholder="Enter desired country"
                  required
                />
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="IELTS_PTE_Score">IELTS/PTE Score</label>
                <input
                  type="text"
                  name="IELTS_PTE_Score"
                  id="IELTS_PTE_Score"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={value.IELTS_PTE_Score}
                  onChange={(e) =>
                    setValue({
                      ...value,
                      IELTS_PTE_Score: e.currentTarget.value,
                    })
                  }
                  placeholder="Enter desired country"
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="dateOfBirth">
                  Date Of Birth:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="date"
                  name="dateOfBirth"
                  id="dateOfBirth"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  placeholder=""
                  value={value.dateOfBirth}
                  onChange={(e) =>
                    setValue({
                      ...value,
                      dateOfBirth: e.currentTarget.value,
                    })
                  }
                  required
                />
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="passport">Passport Number</label>
                <input
                  type="text"
                  name="passport"
                  id="passport"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={value.passport}
                  onChange={(e) =>
                    setValue({ ...value, passport: e.currentTarget.value })
                  }
                  placeholder="Enter passport"
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="occupation">
                  Occupation:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="occupation"
                  id="occupation"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  placeholder="Enter occupation"
                  required
                  value={value.occupation}
                  onChange={(e) =>
                    setValue({
                      ...value,
                      occupation: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="region">
                  Region:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="region"
                  id="region"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={value.region}
                  onChange={(e) =>
                    setValue({ ...value, region: e.currentTarget.value })
                  }
                  placeholder="Enter region"
                  required
                />
              </div>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="matric">
                Matric:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input
                type="text"
                name="matric"
                id="matric"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                value={value.matric}
                onChange={(e) =>
                  setValue({
                    ...value,
                    matric: e.currentTarget.value,
                  })
                }
                placeholder="Enter matriculation"
                required
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="levelOfEducation" className="tracking-wide">
                Highest Level Of Edcuation / Year:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input
                type="text"
                name="levelOfEducation"
                id="levelOfEducation"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                placeholder="Enter level of edcuation"
                required
                value={value.levelOfEducation}
                onChange={(e) =>
                  setValue({
                    ...value,
                    levelOfEducation: e.currentTarget.value,
                  })
                }
              />
            </div>

            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="file">
                CV:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input
                type="file"
                name="files"
                onChange={handleFileChange}
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                id="file"
                ref={fileInputRef}
                multiple
                required
              />

              {/* Optional: Display selected file names */}
              {files &&
                files.map((file, index) => (
                  <div key={index} className="mt-2 text-gray-700">
                    {file.data.name}
                  </div>
                ))}
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-9 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {isLoading ? "Loading..." : "Save"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ZSAApplyForm;
