"use client";

import * as React from "react";

import { Button } from "../../../shadcn/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../shadcn/dropdown-menu";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import addApplicantAction from "../../../../redux/Actions/addApplicantAction";

export function Statusbar({ emp_id, value }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const userId = useSelector((state) => state.filterReducer);
  const dispatch = useDispatch();
  const request = async (status) => {
    const PUT_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants/${emp_id}`;
    try {
      setIsLoading(true);
      await axios.put(PUT_EMP, JSON.stringify({ ...value, status: status }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      const GET_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants`;
      const response = await axios.get(GET_EMP);
      dispatch(addApplicantAction((response?.data).reverse()));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button
            variant="outline"
            className={`${value.status === "Active"
                ? `text-green-400`
                : value.status === "InActive"
                  ? `text-red-400`
                  : value.status === "In Process"
                    ? `text-blue-400`
                    : ``
              }`}
          >
            {isLoading ? "Loading..." : value.status}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel className="text-gray-600">
            Change Status
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup value={value.status} onValueChange={request}>
            <DropdownMenuRadioItem
              className="cursor-pointer text-green-400 "
              value="Active"
            >
              Active
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem
              className="cursor-pointer  text-red-400"
              value="InActive"
            >
              InActive
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem
              className="cursor-pointer  text-blue-400"
              value="In Process"
            >
              In Process
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
