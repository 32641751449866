import { useSelector } from "react-redux";

function GridView() {
  const gridData = useSelector((state) => state.addApplicantReducer);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 bg-white">
      {gridData.map((value, index) => (
        <div key={index}>
          <div className="pl-6 pt-6 pb-6 pr-6 lg:pl-8 lg:pt-8 lg:pb-8 lg:pr-0 bg-white rounded-lg shadow-lg shadow-gray-50 border border-gray-200">
            <div className="font-medium bg-white text-gray-700">
              <div className="items-top flex space-x-2 bg-white">
                <div className="grid gap-1.5 leading-none bg-inherit">
                  <label
                    htmlFor="terms1"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 bg-inherit"
                  >
                    <span className="text-[16px] tracking-wider font-[Poppins] text-gray-500 bg-inherit">
                      {value.firstName}
                    </span>
                  </label>
                  <p className="text-[12px] text-gray-400 font-[Poppins] bg-inherit mb-2">
                    {value.email}
                  </p>
                  <p className="text-[14px] text-gray-600 tracking-wide bg-inherit">
                    Customer Success Management...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default GridView;
