import { IoWalletOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { LuDollarSign } from "react-icons/lu";
import DashboardCards from "./DashboardCards";
import { CiUser } from "react-icons/ci";
import DataChart from "./DataChart";
import React from "react";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const leades = useSelector((state) => state.addApplicantReducer);
  const DashboardCardData = [
    {
      title: "Total Leades",
      value: leades.length,
      icon: <CiUser className="bg-white" />,
    },
    {
      title: "Total Contacts",
      value: 0,
      icon: <IoCallOutline className="bg-white" />,
    },
    {
      title: "Detail Closed",
      value: 0,
      icon: <IoWalletOutline className="bg-white" />,
    },
    {
      title: "Revenue Generated",
      value: 0,
      icon: <LuDollarSign className="bg-white" />,
    },
  ];
  return (
    <section className="main-container">
      <div className="flex flex-col justify-around sm:flex-row sm:flex-wrap md:flex-row lg:flex-row gap-4">
        {DashboardCardData.map((card, index) => (
          <DashboardCards
            key={index}
            title={card.title}
            value={card.value}
            icon={card.icon}
          />
        ))}
      </div>
      <div className="mb-5">
        <DataChart />
      </div>
    </section>
  );
};

export default Dashboard;
