import svg from "../../../Assets/no-reports.svg";

const DataChart = () => {
  return (
    <>
      <div className="grid sm:grid-cols-1 md:grid-cols-2  gap-8 mt-5">
        <div className="w-full bg-white  h-[70vh] flex flex-col border border-zinc-100 shadow-xl shadow-gray-100 rounded-lg overflow-hidden">
          <h3 className="bg-white border-b mt-4 pb-4 px-5 font-semibold text-gray-600">
            Won & Lost Contacts
          </h3>
          <div className="w-full h-full bg-white flex flex-col px-7 py-7">
            <div className="border bg-white border-dashed w-full h-full flex flex-col justify-center items-center m-auto rounded-lg">
              <div className="w-[40%] h-[40%] bg-white">
                <img src={svg} alt="Image1" className="bg-white" />
              </div>
              <div>
                <h1 className="flex justify-center pt-5 bg-white text-[20px] text-gray-600 font-[Poppins]">
                  No data available.
                </h1>
                <p className="text-[12px] text-gray-400 font-[Poppins] bg-white">
                  Please change the data range and try again.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-white  h-[70vh] flex flex-col border border-zinc-100 shadow-xl shadow-gray-100 rounded-lg overflow-hidden">
          <h3 className="bg-white border-b mt-4 pb-4 px-5 font-semibold text-gray-600">
            Won & Lost Contacts
          </h3>
          <div className="w-full h-full bg-white flex flex-col px-7 py-7">
            <div className="border bg-white border-dashed w-full h-full flex flex-col justify-center items-center m-auto rounded-lg">
              <div className="w-[40%] h-[40%] bg-white">
                <img src={svg} alt="Image1" className="bg-white" />
              </div>
              <div>
                <h1 className="flex justify-center pt-5 bg-white text-[20px] text-gray-600 font-[Poppins]">
                  No data available.
                </h1>
                <p className="text-[12px] text-gray-400 font-[Poppins] bg-white">
                  Please change the data range and try again.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataChart;
