import TableDemo from "./applicantsTable/TableDemo";
import { useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa6";
import { useState } from "react";
import ZIRApplyForm from "../../form/applyForm/ZIRApplyForm";
import ZSAApplyForm from "../../form/applyForm/ZSAApplyForm";
import ZTTApplyForm from "../../form/applyForm/ZTTApplyForm";

const Applicants = () => {
  const isLoading = useSelector((state) => state.isLoadingReducer);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const userId = useSelector((state) => state.filterReducer);

  const openModel = () => {
    setIsModelOpen(true);
  };
  const closeModel = () => {
    setIsModelOpen(false);
  };

  const tableData = useSelector((state) => state.addApplicantReducer);
  const tableHeader = [
    "NAME",
    "PHONE NUMBER",
    "C.N.I.C Number",
    "Occupation",
    "Desired Country",
    "Status",
    " ",
  ];
  const tableScheme = [
    ["firstName", "lastName", "email"],
    ["phoneNumber"],
    ["cnic"],
    ["occupation"],
    ["desiredCountry"],
    ["status"],
  ];
  return (
    <>
      <TableDemo
        tableHeader={tableHeader}
        tableScheme={tableScheme}
        tableData={tableData}
        isLoading={isLoading}
        status={true}
      />
      <button
        className="fixed bottom-2 right-2 md:bottom-7 md:right-2"
        onClick={openModel}
      >
        <div className="border p-4 bg-[#2C7BE5] rounded-[100%]">
          <FaPlus className="bg-[#2C7BE5] text-white text-xl font-bold" />
        </div>
      </button>
      {(userId === "66fc1c3696ba6984b614bd4a") ? (
        <ZIRApplyForm isOpen={isModelOpen} onClose={closeModel} />
      ) : userId === "66fc1c8d96ba6984b614bd4c" ? (
        <ZSAApplyForm isOpen={isModelOpen} onClose={closeModel} />
      ) : (userId === "66fc1cad96ba6984b614bd4e" && (
        <ZTTApplyForm isOpen={isModelOpen} onClose={closeModel} />
      )
      )}
    </>
  );
};

export default Applicants;
